.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageContent {
  padding: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#sausage-logo {
  width: 100%;
  object-fit: cover;
}

/* Nav */
.nav-link.active {
  font-weight: 500;
}
.nav-link {
  border-radius: 6px;
}
.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: background-color 0.2s;
}
.navbar {
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.05);
}

body {
  --bs-dark-rgb: 117, 171, 57;
}

.form-label {
  margin-bottom: 2px;
}

.form-list {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.carousel-container {
  height: 30vh;
}

.carousel-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner {
  background-color: rgb(202, 124, 202);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  position: relative;
}

.banner a {
  color: rgb(44, 44, 237); /* You can choose any color you prefer */
  text-decoration: none;
}

.close-icon {
  cursor: pointer;
}

.object-display ul {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 20px;
}

.object-display li {
  position: relative;
  margin-bottom: -10px;
}

.object-display li::before {
  content: "•"; /* Custom bullet point */
  position: absolute;
  left: -15px; /* Adjust position */
  color: #333; /* Custom color */
}

.object-display h2 {
  margin-top: 0;
}
