.leaderboard-container {
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.game-selector {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.leaderboard-table {
  border-collapse: collapse;
  overflow-x: auto;
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.leaderboard-table th {
  background-color: #f1f1f1;
}

.leaderboard-table input[type="number"] {
  width: 60px;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.leaderboard-table button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.leaderboard-table button:hover {
  background-color: #0056b3;
}
